import * as React from 'react'
import { inject, observer } from 'mobx-react'

import InformationContent from 'src/components/Information/InformationContent'
import { getSeoData } from 'src/helpers/getSeoData'
import SEO from 'src/components/SEO/SEO'

type Props = {
  pageContext: any
  preview?: any
}

@inject('preview')
@observer
class Information extends React.Component<Props> {
  render() {
    const { pageContext, preview } = this.props
    let { page } = pageContext

    if (preview.previewData) {
      const previewData = preview.getCurrentPageContext(
        page.contentType,
        page.locale,
        page.slug
      )
      if (previewData) {
        page = previewData
      }
    }

    const { seoData } = page
    const generatedData = getSeoData(seoData)
    if(!generatedData.seoTitle){
      generatedData.seoTitle = page.title
    }
    
    return (
      <div className="container">
        <SEO {...generatedData} />
        <InformationContent content={page.content} />
      </div>
    )
  }
}

export default Information
