import * as React from 'react'

import RichText from 'src/components/Modules/RichText'

import styles from './InformationContent.module.scss'

type Props = {
  content: any
}

const InformationContent = ({ content }: Props) => (
  <div className={styles.informationWrapper}>
    <RichText content={content} />
  </div>
)

export default InformationContent
